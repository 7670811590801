@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Babylonica&family=Montserrat:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700;800&family=Righteous&family=Roboto+Mono:wght@400;500;600;700&display=swap");


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
 
}

.swiper  {
 
  width: 80%;
  min-height: 50vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swiper-slide img {
  display: block;
  width: 80%;
  max-height: 50vh;
  object-fit: fill;
}

@media (max-width:768px) {
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100vh;
    /* object-fit: ; */
  }
}

.active{
  /* background-color: #537FE7; */
  border-radius: 20rem;
  color: #000;
}
.hoverClass{
  background-color: #537FE7;
  border-radius: 20rem;
  color: #828286;
}
#blob{
  background: linear-gradient(to right, #fcffbb,rgb(234, 222, 100));
  position: absolute;
  overflow-x: hidden;
  height: 500px;
  aspect-ratio: 1;
  right: 0;
  top: 0;
  translate: -50% -50%;
  border-radius: 50%;
  animation: rotate 10s infinite;
  filter: blur(100px);
  z-index: -67;
}

@keyframes rotate{
  from{
    rotate:0deg;
  }
  50%{
    scale: 1 1.2;
  }
  75%{
    scale: 0.8;
  }
  to{
    rotate: 360deg;
  }
}
@media (max-width: 768px) {
  #blob{
    left: 0;
  }
  @keyframes rotate{
    from{
      rotate:0deg;
    }
    50%{
      scale: 1  ;
    }
    to{
      rotate: 360deg;
    }
  }
}
